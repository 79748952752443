import api from '../interceptors/auth.interceptor';

class AiService {
  // getAll() {
  //   return api.get
  // }

  create(
    definitions,
  ) {
    return api.post(process.env.REACT_APP_API_URL + '/api/ai/', {
      definitions: definitions,
    });
  }
}

const aiService = new AiService();
export default aiService;