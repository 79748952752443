import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { startAuthentication } from '@simplewebauthn/browser';


import AuthService from "../services/auth.service";

import authService from "../services/auth.service";
import Alert from "./alert.component";

const required = value => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

export default function Login()  {
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    alertType: 'danger',
    alertTitle: 'Error',
    alertMessage: '',
    show: false
  });
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [allowReverify, setAllowReverify] = useState(false);


  const lastMessage = localStorage.getItem("last-message");
  if (lastMessage) {
    localStorage.removeItem("last-message");
  }

  let form = useRef();
  let checkBtn = useRef();
  let navigate = useNavigate();


  const hideMessage = () => {
    setAlert(alert => ({...alert, 
      show: false,
    }));
  }

  const setFailureMessage = (message) => {
    if (!message) {
      hideMessage();
    } else {
      setAlert(alert => ({...alert, 
        show: true,
        alertType: 'danger',
        alertTitle: 'Error',
        alertMessage: message
      }));
    }
  }

  const setSuccessMessage = (message) => {
    if (!message) {
      hideMessage();
    } else {
      setAlert(alert => ({...alert, 
        show: true,
        alertType: 'success',
        alertTitle: 'Success',
        alertMessage: message
      }));
    }
  }

  const onPasswordReminderRequested = (e) => {
    e.preventDefault();
    setLoading(true);

    authService.requestPasswordReset(username).then(
      response => {
        setSuccessMessage(response.data.message);
      },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setFailureMessage(resMessage);
        }
    );
  }

  const loginViaPasskey = (e) => { 
    e.preventDefault();

    if (!username) {
      setFailureMessage('Please enter a username');
      return;
    }

    hideMessage();
    setLoading(true);

    authService.getPasskeyAuthenticationOptions(username).then(
      response => {
        const optionsJSON = response.data;
        // Pass the options to the authenticator and wait for a response
        try {
          return startAuthentication({ optionsJSON }).then(
            authenticationResponse => {
              authService.loginWithPasskey(username, authenticationResponse).then(
                response => {
                  setLoading(false);
                  setSuccessMessage(response.data.message);
                  navigate("/user");
                  window.location.reload();
                },
                error => {
                  const resMessage =
                    (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                    error.message ||
                    error.toString();
        
                  setLoading(false);
                  setFailureMessage(resMessage);
                }
              );
            }
          )
        } catch (error) {
          setLoading(false);
          if (error.name === 'InvalidStateError') {
            setFailureMessage('Error: Authenticator was probably already registered by user');
          } else {
            setFailureMessage(error);
          }
          
          throw error;
        }
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        setFailureMessage(resMessage);
      }
    );

  }

  const onResendVerificationRequested = (e) => {
    e.preventDefault();
    setAllowReverify(false);

    authService.resendVerification(username, password).then(
      response => {
        setLoading(false);
        setSuccessMessage(response.data.message);
      },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

       
          setLoading(false);
          setFailureMessage(resMessage);
        }
    );
  }

  const handleLogin = (e) => {
    e.preventDefault();

    hideMessage();

    setLoading(true);
    form.validateAll();

    if (checkBtn.context._errors.length === 0) {
      AuthService.login(username, password).then(
        () => {
         navigate("/user");
          window.location.reload();
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setLoading(false);
          setFailureMessage(resMessage);
          setAllowReverify(error.response && error.response.data ? error.response.data.allow_reverify : false);
        }
      );
    } else {
      setLoading(false);
    }
  }

    return (
      <div className="col-md-12">
        <Alert variant={alert.alertType} title={alert.alertTitle} text={alert.alertMessage} position="top-end" initialShow={alert.show} />

        <div className="card card-container text-center mx-auto bg-primary" Style="background-color: white !important">
          <div className="card-title">
            Login
          </div>
          <div className="card-body">
            <img
              src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
              alt="profile-img"
              className="profile-img-card"
            />

            <Form
              onSubmit={handleLogin}
              ref={c => {
                form = c;
              }}
            >
              <div className="mb-3">
                <label htmlFor="username">Username</label>
                <Input
                  type="text"
                  className="form-control"
                  name="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  validations={[required]}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="password">Password</label>
                <Input
                  type="password"
                  className="form-control"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  validations={[required]}
                />
              </div>

              <div className="mb-3">
                <button
                  className="btn btn-primary btn-block mr-1"
                  disabled={loading}
                >
                  {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  <span>Login</span>
                </button>
                <a className="btn btn-secondary float-end" href="/register">Register</a>
                
              </div>
              <div className="mb-3">
                <input 
                  type="button" 
                  onClick={loginViaPasskey} 
                  value="Login with passkey"
                  />
              </div>
              <div>
                <button className="btn btn-link" onClick={onPasswordReminderRequested}>Forgot my password</button>
              </div>
              {allowReverify && 
                <button className="btn btn-primary btn-block" onClick={onResendVerificationRequested}>Resend Verification Email</button>
              }




              <CheckButton
                style={{ display: "none" }}
                ref={c => {
                  checkBtn = c;
                }}
              />
            </Form>
          </div>
        </div>
      </div>
    );
}
