import axios from "axios";

const API_URL = `${process.env.REACT_APP_API_URL}/api/auth/`;

class AuthService {
  updatePassword(token, password) {
    return axios.post(`${API_URL}update_password`, { 
      token: token,
      password: password
    });
  }
  
  requestPasswordReset(username) {
    return axios.post(`${API_URL}reset_password`, { 
      username: username,
    });
  }

  resendVerification(username, password) {
    return axios.post(`${API_URL}resend-verification-email`, { 
      username,
      password 
    });
  };

  async login(username, password) {
    const response = await axios
      .post(API_URL + "signin", {
        username,
        password
      });
    if (response.data.access_token) {
      localStorage.setItem("user", JSON.stringify(response.data));
    }
    return response.data;
  }

  async impersonate(accessToken, userData) {
    const response = await axios
      .post(API_URL + "impersonate", {
        userData,
      }, { headers: { 'x-access-token': accessToken } });
    if (response.data.access_token) {
      localStorage.setItem("user", JSON.stringify(response.data));
    }
    return response.data;
  }

  logout() {
    localStorage.removeItem("user");
    localStorage.removeItem('chosenGroup')
    localStorage.removeItem('chosenView')
  }

  async verifyEmail(token) {
    return axios.post(API_URL + "verify-email", {
      token: token
    });
  }

  async register(username, email, password, inviteUuid) {
    return axios.post(API_URL + "signup", {
      username,
      email,
      password,
      inviteUuid
    });
  }

  registerWithPasskey(username, email, password) {
    return axios.post(API_URL + "passkey/signup", {
      username,
      email,
      password
    }, { withCredentials: true });
  }

  getPasskeyResponse(makeCredResponse) {
    return axios.post(API_URL + "passkey/response",
      makeCredResponse,
      { withCredentials: true} 
    );
  }

  async getPasskeyAuthenticationOptions(username) {
    return axios.get(API_URL + `passkey/${encodeURIComponent(username)}/authentication_options`,
      { withCredentials: true }
    );
  }

  async loginWithPasskey(username, authenticationResponse) {
    const response = await axios.post(API_URL + "passkey/signin", {
      credentials: authenticationResponse, 
      username: username
    }, { withCredentials: true });

    if (response.data.access_token) {
      localStorage.setItem("user", JSON.stringify(response.data));
    }
    return response;
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));
  }
}

const authService = new AuthService();
export default authService;