import { useState } from 'react';

import Alert from './alert.component.js';

import userEntriesService from '../services/user-entries-service.js';

export default function UploadResultsPDF() {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [results, setResults] = useState([]);
  const [alert, setAlert] = useState({
    alertType: 'danger',
    alertTitle: 'Error',
    alertMessage: '',
    show: false
  });
  
  const handleUpload= (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", file);

    setLoading(true);

    // You can write the URL of your server or any other endpoint used for file upload
    userEntriesService.uploadPDF(formData).then(
      response => {
        setLoading(false);
        setSuccessMessage(response.data.message);
        setResults(response.data.results)
      }, 
      error => {
        setLoading(false);
        setFailureMessage((error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString())
      }
    )
  } 

  const handleFileChange = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };


  const setFailureMessage = (message) => {
    setAlert(alert => ({...alert, 
      show: true,
      alertType: 'danger',
      alertTitle: 'Error',
      alertMessage: message
    }));
  }

  const setSuccessMessage = (message) => {
    setAlert(alert => ({...alert, 
      show: true,
      alertType: 'success',
      alertTitle: 'Success',
      alertMessage: message
    }));
  };
  
  return (
    <>
      {loading && (
        <span className="spinner-border spinner-border-sm"></span>
      )}
      <Alert variant={alert.alertType} title={alert.alertTitle} text={alert.alertMessage} position="top-end" initialShow={alert.show} />
      <div className="mb-3">
        <label htmlFor="groupName" className="form-label">
          <h3>PDF File</h3>
        </label>
        </div>
      <div>
        <input id="file" type="file" onChange={handleFileChange} />
      </div>
      {file && (
        <div className="card">
          <div className="card-body">
          <h5 className="card-title">File details:</h5>
            <div className="card-text">
              <ul>
                <li>Name: {file.name}</li>
                <li>Type: {file.type}</li>
                <li>Size: {file.size} bytes</li>
              </ul>
            </div>
          </div>
        </div>
      )}

      {file && <button onClick={handleUpload}>Upload a file</button>}
        
      {results.length > 0 && results.map((result) => (
        <div key={result.id}>
          <h5>{result.definitionName}</h5>
          <p>{result.measurementUnitTypeName}</p>
          <p>{result.result}</p>
        </div>
      ))}
    </>
  )
}