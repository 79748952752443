import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import { startRegistration } from '@simplewebauthn/browser';


import AuthService from "../services/auth.service";
import inviteService from "../services/invite.service";

const required = value => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const verifyEmail = value => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

const verifyUsername = value => {
  if (value.length < 3 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        The username must be between 3 and 20 characters.
      </div>
    );
  }
};

const verifyPassword = value => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        The password must be between 6 and 40 characters.
      </div>
    );
  }
};

export default function Register()  {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [successful, setSuccessful] = useState(false);
  const [message, setMessage] = useState("");
  const [isPasskeyFlow, setIsPasskeyFlow] = useState(false);
  
  let { inviteUuid } = useParams();
  let checkBtn = useRef()
  let form = useRef();

  useEffect(() => {

    if (!inviteUuid) return;
    inviteService.get(inviteUuid).then(
      response => {
        const invite = response.data.invite;
        const username = invite.invited_email.split("@")[0];
        setUsername(username);
        setEmail(invite.invited_email);
        setMessage(response.data.message);
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setSuccessful(false);
        setMessage(resMessage)
      }
    );
  }, [inviteUuid]);

  const handleRegisterWithPasskey = (e) => {
    e.preventDefault();

    setMessage("");
    setSuccessful(false);

    form.validateAll();

    AuthService.registerWithPasskey(
      username,
      email,
      password
    ).then(
      response => {
        return startRegistration({optionsJSON: response.data.serverCredentials})
          .catch((error) => {
            // Some basic error handling
            if (error.name === 'InvalidStateError') {
              console.log('Error: Authenticator was probably already registered by user');
            } else {
              console.log(error);
            }
      
            throw error;
          })
      })
    .then(
      credentials => {
        return AuthService.getPasskeyResponse(credentials);
        })
    .then(
      response => {
        if (response.data.success) {
          setMessage(response.data.message);
          setSuccessful(true);
        } else {
          setSuccessful(false);
          setMessage(response.data.message);
        }
      },
      error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setSuccessful(false);
          setMessage(resMessage);
        }
    );
  }

  const handleRegister = (e) => {
    e.preventDefault();

    setMessage("");
    setSuccessful(false);

    form.validateAll();

    if (checkBtn.context._errors.length === 0) {

      AuthService.register(
        username,
        email,
        password,
        inviteUuid
      ).then(
        response => {
          if (response.data.success) {
            setMessage(response.data.message);
            setSuccessful(true);
          } else {
            setSuccessful(false);
            setMessage(response.data.message);
          }
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setSuccessful(false);
          setMessage(resMessage);
        }
      );
    }
  }

  return (
    <div className="col-md-12">
      <div className="card card-container text-center mx-auto">
        <img
          src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
          alt="profile-img"
          className="profile-img-card"
        />

        <Form
          onSubmit={handleRegister}
          ref={c => {
            form = c;
          }}
        >
          {!successful && (
            
            <div>
              <div className="mb-3">
                <label htmlFor="username">Username</label>
                <Input
                  type="text"
                  className="form-control"
                  name="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  validations={[required, verifyUsername]}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="email">Email</label>
                <Input
                  type="text"
                  className="form-control"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  validations={[required, verifyEmail]}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="password">Password</label>
                <Input
                  type="password"
                  className="form-control"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  validations={[required, verifyPassword]}
                />
              </div>

              <div className="mb-3">
                <button className="btn btn-primary btn-block">Sign Up</button>
              </div>
            </div>
          )}

          {!successful && (
            <div className="mb-3">
               <input 
                type="button" 
                defaultChecked={isPasskeyFlow} 
                onClick={handleRegisterWithPasskey} 
                value="Register with passkey"
              />
              </div>
          )}

          {message && (
            <div className="mb-3">
              <div
                className={
                  successful
                    ? "alert alert-success"
                    : "alert alert-danger"
                }
                role="alert"
              >
                {message}
              </div>
              {successful && (
                <a href="/login" className="btn btn-primary btn-block">Login</a>
              )}
            </div>
          )}
          <CheckButton
            style={{ display: "none" }}
            ref={c => {
              checkBtn = c;
            }}
          />
        </Form>
      </div>
    </div>
  );
}